<template>
  <div class="select-address-wrapper">
    <m-map @addressChange="handleAddressChange"></m-map>
    <div class="position" v-if="addressInfo.address">
      <h4>当前位置</h4>
      <p>{{addressInfo.address}}</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'selectAddress',
  computed: {
    ...mapState(['addressInfo'])
  },
  methods: {
    ...mapMutations(['SET_ADDRESS_INFO']),
    handleAddressChange (data) {
      this.SET_ADDRESS_INFO(data)
    }
  },
  components: {
    'm-map': () => import('@/components/MyMap')
  }
}
</script>

<style lang="scss" scoped>
  .select-address-wrapper{
    .position{
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      padding: 24px 28px;
      background: #fff;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      h4{
        color: #333;
        font-size: 32px;
        font-weight: 600;
        width: 140px;
        margin-right: 24px;
      }
      p{
        flex: 1;
        font-size: 26px;
        color: #526779;
      }
    }
  }
</style>
